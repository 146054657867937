/*
  Template Name: DesCode - Multiple App Landing Pages
  Theme URL: https://codextheme.codexperts.in/descode
  Description: DesCode - Multiple App Landing Pages
  Author: codextheme
  Author URL: https://www.templatemonster.com/authors/codextheme
  Version: 1.0.0  
  Tags:React Landing Page, Responsive, HTML5 template, Mobile, Application, One Page, Landing, Product, Mobile App
*/

/* ==================
  01. TYPOGRAPHY
  ==================*/

/*-------------HEADERS------------*/
  h1, h2, h3, h4, h5, h6 {  
    color: #463853;
    font-family: "Google Sans", "Roboto", sans-serif;
    font-weight: 500;
    letter-spacing: 0;
  }
  
  /* Header H6 */
  h6.h6-xs { font-size: 1.0625rem; }   /* 17px */
  h6.h6-sm { font-size: 1.125rem; }    /* 18px */
  h6.h6-md { font-size: 1.15625rem; }  /* 18.5px */
  h6.h6-lg { font-size: 1.1875rem; }   /* 19px */
  h6.h6-xl { font-size: 1.21875rem; }  /* 19.5px */
  

  /* Header H5 */
  h5.h5-xs { font-size: 1.25rem; }    /* 20px */
  h5.h5-sm { font-size: 1.375rem; }   /* 22px */
  h5.h5-md { font-size: 1.5rem; }     /* 24px */
  h5.h5-lg { font-size: 1.625rem; }   /* 26px */
  h5.h5-xl { font-size: 1.75rem; }    /* 28px */
  

  /* Header H4 */
  h4.h4-xs { font-size: 1.875rem; }   /* 30px */
  h4.h4-sm { font-size: 2rem; }       /* 32px */
  h4.h4-md { font-size: 2rem; }       /* 34px */
  h4.h4-lg { font-size: 2.25rem; }    /* 36px */
  h4.h4-xl { font-size: 2.375rem; }   /* 38px */
  
  /* Header H3 */
  h3.h3-xs { font-size: 2.5rem; }     /* 40px */
  h3.h3-sm { font-size: 2.625rem; }   /* 42px */
  h3.h3-md { font-size: 2.75rem; }    /* 44px */
  h3.h3-lg { font-size: 2.875rem; }   /* 46px */
  h3.h3-xl { font-size: 3rem; }       /* 48px */
  

  /* Header H2 */
  h2.h2-xs { font-size: 3.125rem; }   /* 50px */
  h2.h2-sm { font-size: 3.25rem; }    /* 52px */
  h2.h2-md { font-size: 3.2rem; }     /* 54px */
  h2.h2-lg { font-size: 3.5rem; }     /* 56px */ 
  h2.h2-xl { font-size: 3.75rem; }    /* 60px */
  

  h2.h2-title-xs { font-size: 4.0625rem; } /* 65px */
  h2.h2-title-sm { font-size: 4.375rem; }  /* 70px */
  h2.h2-title-md { font-size: 4.6875rem; } /* 75px */
  h2.h2-title-lg { font-size: 5rem; }      /* 80px */
  h2.h2-title-xl { font-size: 5.3125rem; } /* 85px */
  


  /*--------------PARAGRAPHS------------*/

  p { font-size: 1.0625rem; }  /* 17px */
  p.p-md { font-size: 1.15625rem; line-height: 1.6; }  /* 18.5px */
  p.p-lg { font-size: 1.1875rem;  line-height: 1.6; }  /* 19px */
  p.p-xl { font-size: 1.25rem;  line-height: 1.6; }    /* 20px */
  



/* ===========================
  02. LAYOUT SPACING & INDENTS
  ===========================*/
  
  .wide-100 { padding-top: 100px; padding-bottom: 100px; }
  .wide-90 { padding-top: 100px; padding-bottom: 90px; }
  .wide-80 { padding-top: 100px; padding-bottom: 80px; }
  .wide-70 { padding-top: 100px; padding-bottom: 70px; }
  .wide-60 { padding-top: 100px; padding-bottom: 60px; }
  .wide-50 { padding-top: 100px; padding-bottom: 50px; }
  .wide-40 { padding-top: 100px; padding-bottom: 40px; }
  .wide-30 { padding-top: 100px; padding-bottom: 30px; }
  .wide-20 { padding-top: 100px; padding-bottom: 20px; }
  

  /*-----------Margin Top-------------*/

  .mt-100 { margin-top: 100px; }
  .mt-95 { margin-top: 95px; }
  .mt-90 { margin-top: 90px; }
  .mt-85 { margin-top: 85px; }
  .mt-80 { margin-top: 80px; }
  .mt-75 { margin-top: 75px; }
  .mt-70 { margin-top: 70px; }
  .mt-65 { margin-top: 65px; }
  .mt-60 { margin-top: 60px; }
  .mt-55 { margin-top: 55px; }
  .mt-50 { margin-top: 50px; }
  .mt-45 { margin-top: 45px; }
  .mt-40 { margin-top: 40px; }
  .mt-35 { margin-top: 35px; }
  .mt-30 { margin-top: 30px; }
  .mt-25 { margin-top: 25px; }
  .mt-20 { margin-top: 20px; }
  .mt-15 { margin-top: 15px; }
  .mt-10 { margin-top: 10px; }
  .mt-5 { margin-top: 5px; }
  .mt-0 { margin-top: 0 !important; }
  


  /*----------Margin Top Inverse---------*/
  
  .mt-inverse-150 { margin-top: -150px; }
  .mt-inverse-140 { margin-top: -140px; }
  .mt-inverse-130 { margin-top: -130px; }
  .mt-inverse-120 { margin-top: -120px; }
  .mt-inverse-110 { margin-top: -110px; }
  .mt-inverse-100 { margin-top: -100px; }
  .mt-inverse-90 { margin-top: -90px; }
  .mt-inverse-80 { margin-top: -80px; }
  .mt-inverse-70 { margin-top: -70px; }
  .mt-inverse-60 { margin-top: -60px; }
  .mt-inverse-50 { margin-top: -50px; }
  .mt-inverse-40 { margin-top: -40px; }
  .mt-inverse-30 { margin-top: -30px; }
  .mt-inverse-20 { margin-top: -20px; }
  


  /*-------------Margin Bottom-----------*/

  .mb-100 { margin-bottom: 100px; }
  .mb-95 { margin-bottom: 95px; }
  .mb-90 { margin-bottom: 90px; }
  .mb-85 { margin-bottom: 85px; }
  .mb-80 { margin-bottom: 80px; }
  .mb-75 { margin-bottom: 75px; }
  .mb-70 { margin-bottom: 70px; }
  .mb-65 { margin-bottom: 65px; }
  .mb-60 { margin-bottom: 60px; }
  .mb-55 { margin-bottom: 55px; }
  .mb-50 { margin-bottom: 50px; }
  .mb-45 { margin-bottom: 45px; }
  .mb-40 { margin-bottom: 40px; }
  .mb-35 { margin-bottom: 35px; }
  .mb-30 { margin-bottom: 30px; }
  .mb-25 { margin-bottom: 25px; }
  .mb-20 { margin-bottom: 20px; }
  .mb-15 { margin-bottom: 15px; }
  .mb-10 { margin-bottom: 10px; }
  .mb-5 { margin-bottom: 5px!important; }
  .mb-0 { margin-bottom: 0; }
  


  /*--------------Margin Left------------*/
  
  .ml-100 { margin-left: 100px; }
  .ml-95 { margin-left: 95px; }
  .ml-90 { margin-left: 90px; }
  .ml-85 { margin-left: 85px; }
  .ml-80 { margin-left: 80px; }
  .ml-75 { margin-left: 75px; }
  .ml-70 { margin-left: 70px; }
  .ml-60 { margin-left: 60px; }
  .ml-55 { margin-left: 55px; }
  .ml-50 { margin-left: 50px; }
  .ml-45 { margin-left: 45px; }
  .ml-40 { margin-left: 40px; }
  .ml-35 { margin-left: 35px; }
  .ml-30 { margin-left: 30px; }
  .ml-25 { margin-left: 25px; }
  .ml-20 { margin-left: 20px; }
  .ml-15 { margin-left: 15px; }
  .ml-10 { margin-left: 10px; }
  .ml-5 { margin-left: 5px!important; }
  


  /*-------------Margin Right------------*/

  .mr-100 { margin-right: 100px; }
  .mr-95 { margin-right: 95px; }
  .mr-90 { margin-right: 90px; }
  .mr-85 { margin-right: 85px; }
  .mr-80 { margin-right: 80px; }
  .mr-75 { margin-right: 75px; }
  .mr-70 { margin-right: 70px; }
  .mr-65 { margin-right: 65px; }
  .mr-60 { margin-right: 60px; }
  .mr-55 { margin-right: 55px; }
  .mr-50 { margin-right: 50px; }
  .mr-45 { margin-right: 45px; }
  .mr-40 { margin-right: 40px; }
  .mr-35 { margin-right: 35px; }
  .mr-30 { margin-right: 30px; }
  .mr-25 { margin-right: 25px; }
  .mr-20 { margin-right: 20px; }
  .mr-15 { margin-right: 15px; }
  .mr-10 { margin-right: 10px; }
  .mr-5 { margin-right: 5px!important; }
  


  /*---------------Padding Top------------*/
  
  .pt-100 { padding-top: 100px; }
  .pt-95 { padding-top: 95px; }
  .pt-90 { padding-top: 90px; }
  .pt-85 { padding-top: 85px; }
  .pt-80 { padding-top: 80px; }
  .pt-75 { padding-top: 75px; }
  .pt-70 { padding-top: 70px; }
  .pt-65 { padding-top: 65px; }
  .pt-60 { padding-top: 60px; }
  .pt-55 { padding-top: 55px; }
  .pt-50 { padding-top: 50px; }
  .pt-45 { padding-top: 45px; }
  .pt-40 { padding-top: 40px; }
  .pt-35 { padding-top: 35px; }
  .pt-30 { padding-top: 30px; }
  .pt-25 { padding-top: 25px; }
  .pt-20 { padding-top: 20px; }
  .pt-15 { padding-top: 15px; }
  .pt-10 { padding-top: 10px; }
  .pt-5 { padding-top: 5px!important; }
  


  /*-------------Padding Bottom-------------*/
  
  .pb-100 { padding-bottom: 100px; }
  .pb-95 { padding-bottom: 95px; }
  .pb-90 { padding-bottom: 90px; }
  .pb-85 { padding-bottom: 85px; }
  .pb-80 { padding-bottom: 80px; }
  .pb-75 { padding-bottom: 75px; }
  .pb-70 { padding-bottom: 70px; }
  .pb-65 { padding-bottom: 65px; }
  .pb-60 { padding-bottom: 60px; }
  .pb-55 { padding-bottom: 55px; }
  .pb-50 { padding-bottom: 50px; }
  .pb-45 { padding-bottom: 45px; }
  .pb-40 { padding-bottom: 40px; }
  .pb-35 { padding-bottom: 35px; }
  .pb-30 { padding-bottom: 30px; }
  .pb-25 { padding-bottom: 25px; }
  .pb-20 { padding-bottom: 20px; }
  .pb-15 { padding-bottom: 15px; }
  .pb-10 { padding-bottom: 10px; }
  .pb-5 { padding-bottom: 5px!important; }
  



  /*--------------Padding Left------------*/

  .pl-100 { padding-left: 100px; }
  .pl-95 { padding-left: 95px; }
  .pl-90 { padding-left: 90px; }
  .pl-85 { padding-left: 85px; }
  .pl-80 { padding-left: 80px; }
  .pl-75 { padding-left: 75px; }
  .pl-70 { padding-left: 70px; }
  .pl-65 { padding-left: 65px; }
  .pl-60 { padding-left: 60px; }
  .pl-55 { padding-left: 55px; }
  .pl-50 { padding-left: 50px; }
  .pl-45 { padding-left: 45px; }
  .pl-40 { padding-left: 40px; }
  .pl-35 { padding-left: 35px; }
  .pl-30 { padding-left: 30px; }
  .pl-25 { padding-left: 25px; }
  .pl-20 { padding-left: 20px; }
  .pl-15 { padding-left: 15px; }
  .pl-10 { padding-left: 10px; }
  .pl-5 { padding-left: 5px!important; }
  


  /*--------------Padding Right-------------*/
  
  .pr-100 { padding-right: 100px; }
  .pr-95 { padding-right: 95px; }
  .pr-90 { padding-right: 90px; }
  .pr-85 { padding-right: 85px; }
  .pr-80 { padding-right: 80px; }
  .pr-75 { padding-right: 75px; }
  .pr-70 { padding-right: 70px; }
  .pr-65 { padding-right: 65px; }
  .pr-60 { padding-right: 60px; }
  .pr-55 { padding-right: 55px; }
  .pr-50 { padding-right: 50px; }
  .pr-45 { padding-right: 45px; }
  .pr-40 { padding-right: 40px; }
  .pr-35 { padding-right: 35px; }
  .pr-30 { padding-right: 30px; }
  .pr-25 { padding-right: 25px; }
  .pr-20 { padding-right: 20px; }
  .pr-15 { padding-right: 15px; }
  .pr-10 { padding-right: 10px; }
  .pr-5 { padding-right: 5px!important; }
  



  /*-----------Padding Centered---------*/

  .pc-70 { padding-right: 70px; padding-left: 70px; } 
  .pc-65 { padding-right: 65px; padding-left: 65px; } 
  .pc-60 { padding-right: 60px; padding-left: 60px; } 
  .pc-55 { padding-right: 55px; padding-left: 55px; } 
  .pc-50 { padding-right: 50px; padding-left: 50px; } 
  .pc-45 { padding-right: 45px; padding-left: 45px; } 
  .pc-40 { padding-right: 40px; padding-left: 40px; }
  .pc-35 { padding-right: 35px; padding-left: 35px; }
  .pc-30 { padding-right: 30px; padding-left: 30px; }
  .pc-25 { padding-right: 25px; padding-left: 25px; }
  .pc-20 { padding-right: 20px; padding-left: 20px; }
  .pc-15 { padding-right: 15px; padding-left: 15px; }
  .pc-10 { padding-right: 10px; padding-left: 10px; }
  