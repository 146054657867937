@font-face {
    font-family: "flaticon_instant_notion_web";
    src: url("./flaticon_instant_notion_web.ttf?9481e5423a48d0ff62beb77205b30848") format("truetype"),
url("./flaticon_instant_notion_web.woff?9481e5423a48d0ff62beb77205b30848") format("woff"),
url("./flaticon_instant_notion_web.woff2?9481e5423a48d0ff62beb77205b30848") format("woff2"),
url("./flaticon_instant_notion_web.eot?9481e5423a48d0ff62beb77205b30848#iefix") format("embedded-opentype"),
url("./flaticon_instant_notion_web.svg?9481e5423a48d0ff62beb77205b30848#flaticon_instant_notion_web") format("svg");
}

i[class^="flaticon-"]:before, i[class*=" flaticon-"]:before {
    font-family: flaticon_instant_notion_web !important;
    font-style: normal;
    font-weight: normal !important;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.flaticon-price-tag:before {
    content: "\f101";
}
.flaticon-twitter:before {
    content: "\f102";
}
.flaticon-twitter-1:before {
    content: "\f103";
}
.flaticon-twitter-2:before {
    content: "\f104";
}
.flaticon-flash:before {
    content: "\f105";
}
.flaticon-thunder:before {
    content: "\f106";
}
.flaticon-lightning:before {
    content: "\f107";
}
.flaticon-flash-1:before {
    content: "\f108";
}
.flaticon-no-wifi:before {
    content: "\f109";
}
.flaticon-no-wifi-1:before {
    content: "\f10a";
}
.flaticon-wifi:before {
    content: "\f10b";
}
.flaticon-shield:before {
    content: "\f10c";
}
.flaticon-verified:before {
    content: "\f10d";
}
.flaticon-security:before {
    content: "\f10e";
}
.flaticon-security-1:before {
    content: "\f10f";
}
.flaticon-shield-1:before {
    content: "\f110";
}
.flaticon-menu:before {
    content: "\f111";
}
.flaticon-widget:before {
    content: "\f112";
}
.flaticon-widget-1:before {
    content: "\f113";
}
.flaticon-menu-1:before {
    content: "\f114";
}
.flaticon-tag:before {
    content: "\f115";
}
.flaticon-tag-1:before {
    content: "\f116";
}
.flaticon-supermarket:before {
    content: "\f117";
}
.flaticon-book:before {
    content: "\f118";
}
.flaticon-book-1:before {
    content: "\f119";
}
.flaticon-book-2:before {
    content: "\f11a";
}
.flaticon-books:before {
    content: "\f11b";
}
.flaticon-music-album:before {
    content: "\f11c";
}
.flaticon-brightness-and-contrast:before {
    content: "\f11d";
}
.flaticon-dark-mode:before {
    content: "\f11e";
}
.flaticon-night-mode:before {
    content: "\f11f";
}
.flaticon-theme:before {
    content: "\f120";
}
.flaticon-star-1:before {
    content: "\f121";
}
.flaticon-half-star:before {
    content: "\f122";
}
